export default function ({ store, req }) {
  if (process.server) {
    const headers = req && req.headers ? Object.assign({}, req.headers) : {};

    const geoLocation = {};
    geoLocation.countryISOCode = headers['cloudfront-viewer-country'];
    geoLocation.state = headers['cloudfront-viewer-country-region'];

    store.commit('Global/SET_GEO_LOCATION', geoLocation);
  }
}
