import API_END_POINTS from '../api';
import { dataFetch } from '../api/data-fetch';

const state = () => ({
  isActive: false,
  searchResults: [],
});

const actions = {
  async fetchSearch({ commit }, query, fetcher = dataFetch) {
    try {
      const endpoint = `${API_END_POINTS.HEADER_SEARCH}&query=${query}`;
      const response = await fetcher(endpoint);
      if (response) {
        commit('updateSearchResults', response);
      }
    } catch (error) {
      console.error(error);
    }
  },
};

const getters = {
  isSearchActive: state => {
    return state.isActive;
  },
};
const mutations = {
  setActive(state, value) {
    state.isActive = value;
  },
  updateSearchResults(state, results) {
    state.searchResults = results;
  },
  resetSearchResults(state) {
    state.searchResults = [];
  },
};

export default {
  namespaced: true,
  getters,
  state,
  actions,
  mutations,
};
