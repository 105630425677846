import Vue from 'vue';

Vue.filter('replaceDashWithSpace', function (value) {
  let str = value;
  if (typeof str === 'string') {
    str = str.replace(/-/g, ' ');
  }

  return str;
});
