import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _5523f422 = () => interopDefault(import('../components/GroupRacePage.vue' /* webpackChunkName: "components/GroupRacePage.vue" */))

const _0d7e7f66 = () => interopDefault(import('../pages/appterms.vue' /* webpackChunkName: "pages/appterms" */))
const _678fbe18 = () => interopDefault(import('../pages/blackbook/index.vue' /* webpackChunkName: "pages/blackbook/index" */))
const _e688d4c6 = () => interopDefault(import('../pages/bookie-reviews/index.vue' /* webpackChunkName: "pages/bookie-reviews/index" */))
const _6c5e8d1c = () => interopDefault(import('../pages/contact-us.vue' /* webpackChunkName: "pages/contact-us" */))
const _38ca391c = () => interopDefault(import('../pages/debug.vue' /* webpackChunkName: "pages/debug" */))
const _43d05c4a = () => interopDefault(import('../pages/form-finder/index.vue' /* webpackChunkName: "pages/form-finder/index" */))
const _7c8f01bd = () => interopDefault(import('../pages/form-guide/index.vue' /* webpackChunkName: "pages/form-guide/index" */))
const _8adcef90 = () => interopDefault(import('../pages/free-greyhound-racing-tips/index.vue' /* webpackChunkName: "pages/free-greyhound-racing-tips/index" */))
const _21698ae3 = () => interopDefault(import('../pages/free-harness-racing-tips/index.vue' /* webpackChunkName: "pages/free-harness-racing-tips/index" */))
const _5637ba10 = () => interopDefault(import('../pages/free-horse-racing-tips/index.vue' /* webpackChunkName: "pages/free-horse-racing-tips/index" */))
const _5bd6f100 = () => interopDefault(import('../pages/horses/index.vue' /* webpackChunkName: "pages/horses/index" */))
const _b0270804 = () => interopDefault(import('../pages/jockeys/index.vue' /* webpackChunkName: "pages/jockeys/index" */))
const _1b3f5b22 = () => interopDefault(import('../pages/melbourne-cup.vue' /* webpackChunkName: "pages/melbourne-cup" */))
const _477c04b8 = () => interopDefault(import('../pages/melbourne-cup/_childSlug.vue' /* webpackChunkName: "pages/melbourne-cup/_childSlug" */))
const _0b18b312 = () => interopDefault(import('../pages/nocookies.vue' /* webpackChunkName: "pages/nocookies" */))
const _97b5d5f0 = () => interopDefault(import('../pages/predictor/index.vue' /* webpackChunkName: "pages/predictor/index" */))
const _7fd120ce = () => interopDefault(import('../pages/punters-edge/index.vue' /* webpackChunkName: "pages/punters-edge/index" */))
const _93b4e7c4 = () => interopDefault(import('../pages/puntersplus.vue' /* webpackChunkName: "pages/puntersplus" */))
const _2bbf9564 = () => interopDefault(import('../pages/races/index.vue' /* webpackChunkName: "pages/races/index" */))
const _552971ad = () => interopDefault(import('../pages/sectionals/index.vue' /* webpackChunkName: "pages/sectionals/index" */))
const _48c6047a = () => interopDefault(import('../pages/tippers-edge/index.vue' /* webpackChunkName: "pages/tippers-edge/index" */))
const _427eaad2 = () => interopDefault(import('../pages/tracks/index.vue' /* webpackChunkName: "pages/tracks/index" */))
const _5cefed30 = () => interopDefault(import('../pages/trainers/index.vue' /* webpackChunkName: "pages/trainers/index" */))
const _0ce5be1b = () => interopDefault(import('../pages/watch/index.vue' /* webpackChunkName: "pages/watch/index" */))
const _85c7a46c = () => interopDefault(import('../pages/betting/bonus-bets/index.vue' /* webpackChunkName: "pages/betting/bonus-bets/index" */))
const _73701fde = () => interopDefault(import('../pages/form-guide/harness/index.vue' /* webpackChunkName: "pages/form-guide/harness/index" */))
const _2f515466 = () => interopDefault(import('../pages/form-guide/horse-racing/index.vue' /* webpackChunkName: "pages/form-guide/horse-racing/index" */))
const _051a1a09 = () => interopDefault(import('../pages/free-horse-racing-tips/best-bets.vue' /* webpackChunkName: "pages/free-horse-racing-tips/best-bets" */))
const _19c9620c = () => interopDefault(import('../pages/free-horse-racing-tips/best-value.vue' /* webpackChunkName: "pages/free-horse-racing-tips/best-value" */))
const _60890cdf = () => interopDefault(import('../pages/tracks/by-letter/index.vue' /* webpackChunkName: "pages/tracks/by-letter/index" */))
const _46dbd58e = () => interopDefault(import('../pages/tracks/by-state/index.vue' /* webpackChunkName: "pages/tracks/by-state/index" */))
const _1479741b = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _7cf19e46 = () => interopDefault(import('../pages/blackbook/unsubscribe/_token.vue' /* webpackChunkName: "pages/blackbook/unsubscribe/_token" */))
const _45526adc = () => interopDefault(import('../pages/tracks/by-letter/_letter.vue' /* webpackChunkName: "pages/tracks/by-letter/_letter" */))
const _d4b91af4 = () => interopDefault(import('../pages/tracks/by-state/_state.vue' /* webpackChunkName: "pages/tracks/by-state/_state" */))
const _f289e738 = () => interopDefault(import('../pages/form-guide/harness/_slug/race/_raceNumber.vue' /* webpackChunkName: "pages/form-guide/harness/_slug/race/_raceNumber" */))
const _e9f61756 = () => interopDefault(import('../pages/bookie-reviews/_slug.vue' /* webpackChunkName: "pages/bookie-reviews/_slug" */))
const _b4563590 = () => interopDefault(import('../pages/form-guide/_raceType/index.vue' /* webpackChunkName: "pages/form-guide/_raceType/index" */))
const _548118c8 = () => interopDefault(import('../pages/free-horse-racing-tips/_slug.vue' /* webpackChunkName: "pages/free-horse-racing-tips/_slug" */))
const _67b02c3b = () => interopDefault(import('../pages/horses/_slug/index.vue' /* webpackChunkName: "pages/horses/_slug/index" */))
const _286500b9 = () => interopDefault(import('../pages/jockeys/_slug/index.vue' /* webpackChunkName: "pages/jockeys/_slug/index" */))
const _fa717964 = () => interopDefault(import('../pages/joinlink/_bookmakerId/index.vue' /* webpackChunkName: "pages/joinlink/_bookmakerId/index" */))
const _1039a990 = () => interopDefault(import('../pages/link/_bookmakerId/index.vue' /* webpackChunkName: "pages/link/_bookmakerId/index" */))
const _4c6d5e54 = () => interopDefault(import('../pages/race-updates/_updateTypeSlug/index.vue' /* webpackChunkName: "pages/race-updates/_updateTypeSlug/index" */))
const _76518ee0 = () => interopDefault(import('../pages/races/_parentSlug.vue' /* webpackChunkName: "pages/races/_parentSlug" */))
const _07bfaff7 = () => interopDefault(import('../pages/races/_parentSlug/_childSlug.vue' /* webpackChunkName: "pages/races/_parentSlug/_childSlug" */))
const _7b287b7c = () => interopDefault(import('../pages/racing-results/_raceType/index.vue' /* webpackChunkName: "pages/racing-results/_raceType/index" */))
const _40c8098a = () => interopDefault(import('../pages/tracks/_slug.vue' /* webpackChunkName: "pages/tracks/_slug" */))
const _27b756a3 = () => interopDefault(import('../pages/trainers/_slug/index.vue' /* webpackChunkName: "pages/trainers/_slug/index" */))
const _910e3b02 = () => interopDefault(import('../pages/unsubscribe/_token.vue' /* webpackChunkName: "pages/unsubscribe/_token" */))
const _7ba6b7a9 = () => interopDefault(import('../pages/watch/_category/index.vue' /* webpackChunkName: "pages/watch/_category/index" */))
const _3bd6c814 = () => interopDefault(import('../pages/form-guide/_raceType/_meetingSlug/index.vue' /* webpackChunkName: "pages/form-guide/_raceType/_meetingSlug/index" */))
const _16113340 = () => interopDefault(import('../pages/horses/_slug/_tab/index.vue' /* webpackChunkName: "pages/horses/_slug/_tab/index" */))
const _40437d82 = () => interopDefault(import('../pages/jockeys/_slug/_tab/index.vue' /* webpackChunkName: "pages/jockeys/_slug/_tab/index" */))
const _4f1eff60 = () => interopDefault(import('../pages/racing-results/_raceType/_meetingSlug/index.vue' /* webpackChunkName: "pages/racing-results/_raceType/_meetingSlug/index" */))
const _c1276c50 = () => interopDefault(import('../pages/trainers/_slug/_tab/index.vue' /* webpackChunkName: "pages/trainers/_slug/_tab/index" */))
const _96b99ffa = () => interopDefault(import('../pages/watch/_category/_videoSlug/index.vue' /* webpackChunkName: "pages/watch/_category/_videoSlug/index" */))
const _e8f3d9d8 = () => interopDefault(import('../pages/form-guide/_raceType/_meetingSlug/_eventSlug.vue' /* webpackChunkName: "pages/form-guide/_raceType/_meetingSlug/_eventSlug" */))
const _ea3c303c = () => interopDefault(import('../pages/form-guide/_raceType/_meetingSlug/_eventSlug/box-stats.vue' /* webpackChunkName: "pages/form-guide/_raceType/_meetingSlug/_eventSlug/box-stats" */))
const _3e448887 = () => interopDefault(import('../pages/form-guide/_raceType/_meetingSlug/_eventSlug/full-form.vue' /* webpackChunkName: "pages/form-guide/_raceType/_meetingSlug/_eventSlug/full-form" */))
const _ce310452 = () => interopDefault(import('../pages/form-guide/_raceType/_meetingSlug/_eventSlug/odds-comparison.vue' /* webpackChunkName: "pages/form-guide/_raceType/_meetingSlug/_eventSlug/odds-comparison" */))
const _2f97d844 = () => interopDefault(import('../pages/form-guide/_raceType/_meetingSlug/_eventSlug/overview.vue' /* webpackChunkName: "pages/form-guide/_raceType/_meetingSlug/_eventSlug/overview" */))
const _6fd34f00 = () => interopDefault(import('../pages/form-guide/_raceType/_meetingSlug/_eventSlug/speedmap.vue' /* webpackChunkName: "pages/form-guide/_raceType/_meetingSlug/_eventSlug/speedmap" */))
const _c9960ad2 = () => interopDefault(import('../pages/form-guide/_raceType/_meetingSlug/_eventSlug/index.vue' /* webpackChunkName: "pages/form-guide/_raceType/_meetingSlug/_eventSlug/index" */))
const _5d09f64a = () => interopDefault(import('../pages/racing-results/_raceType/_meetingSlug/_eventSlug/index.vue' /* webpackChunkName: "pages/racing-results/_raceType/_meetingSlug/_eventSlug/index" */))
const _12c2d2d3 = () => interopDefault(import('../pages/_slug.vue' /* webpackChunkName: "pages/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/notable-races/",
    component: _5523f422,
    name: "notable-races",
    children: [{
      path: ":parentSlug",
      name: "notable-race-filter-or-content",
      children: [{
        path: ":childSlug?",
        name: "notable-race-tab-content"
      }]
    }]
  }, {
    path: "/group-three-races/",
    component: _5523f422,
    name: "group-three-races",
    children: [{
      path: ":parentSlug",
      name: "group-three-race-filter-or-content",
      children: [{
        path: ":childSlug?",
        name: "group-three-race-tab-content"
      }]
    }]
  }, {
    path: "/group-two-races/",
    component: _5523f422,
    name: "group-two-races",
    children: [{
      path: ":parentSlug",
      name: "group-two-race-filter-or-content",
      children: [{
        path: ":childSlug?",
        name: "group-two-race-tab-content"
      }]
    }]
  }, {
    path: "/listed-races/",
    component: _5523f422,
    name: "listed-races",
    children: [{
      path: ":parentSlug",
      name: "listed-race-filter-or-content",
      children: [{
        path: ":childSlug?",
        name: "listed-race-tab-content"
      }]
    }]
  }, {
    path: "/appterms/",
    component: _0d7e7f66,
    pathToRegexpOptions: {"strict":true},
    name: "appterms"
  }, {
    path: "/blackbook/",
    component: _678fbe18,
    pathToRegexpOptions: {"strict":true},
    name: "blackbook"
  }, {
    path: "/bookie-reviews/",
    component: _e688d4c6,
    pathToRegexpOptions: {"strict":true},
    name: "bookie-reviews"
  }, {
    path: "/contact-us/",
    component: _6c5e8d1c,
    pathToRegexpOptions: {"strict":true},
    name: "contact-us"
  }, {
    path: "/debug/",
    component: _38ca391c,
    pathToRegexpOptions: {"strict":true},
    name: "debug"
  }, {
    path: "/form-finder/",
    component: _43d05c4a,
    pathToRegexpOptions: {"strict":true},
    name: "form-finder"
  }, {
    path: "/form-guide/",
    component: _7c8f01bd,
    pathToRegexpOptions: {"strict":true},
    name: "form-guide"
  }, {
    path: "/free-greyhound-racing-tips/",
    component: _8adcef90,
    pathToRegexpOptions: {"strict":true},
    name: "free-greyhound-racing-tips"
  }, {
    path: "/free-harness-racing-tips/",
    component: _21698ae3,
    pathToRegexpOptions: {"strict":true},
    name: "free-harness-racing-tips"
  }, {
    path: "/free-horse-racing-tips/",
    component: _5637ba10,
    pathToRegexpOptions: {"strict":true},
    name: "free-horse-racing-tips"
  }, {
    path: "/horses/",
    component: _5bd6f100,
    pathToRegexpOptions: {"strict":true},
    name: "horses"
  }, {
    path: "/jockeys/",
    component: _b0270804,
    pathToRegexpOptions: {"strict":true},
    name: "jockeys"
  }, {
    path: "/melbourne-cup/",
    component: _1b3f5b22,
    pathToRegexpOptions: {"strict":true},
    name: "melbourne-cup",
    children: [{
      path: ":childSlug?/",
      component: _477c04b8,
      pathToRegexpOptions: {"strict":true},
      name: "melbourne-cup-childSlug"
    }]
  }, {
    path: "/nocookies/",
    component: _0b18b312,
    pathToRegexpOptions: {"strict":true},
    name: "nocookies"
  }, {
    path: "/predictor/",
    component: _97b5d5f0,
    pathToRegexpOptions: {"strict":true},
    name: "predictor"
  }, {
    path: "/punters-edge/",
    component: _7fd120ce,
    pathToRegexpOptions: {"strict":true},
    name: "punters-edge"
  }, {
    path: "/puntersplus/",
    component: _93b4e7c4,
    pathToRegexpOptions: {"strict":true},
    name: "puntersplus"
  }, {
    path: "/races/",
    component: _2bbf9564,
    pathToRegexpOptions: {"strict":true},
    name: "races"
  }, {
    path: "/sectionals/",
    component: _552971ad,
    pathToRegexpOptions: {"strict":true},
    name: "sectionals"
  }, {
    path: "/tippers-edge/",
    component: _48c6047a,
    pathToRegexpOptions: {"strict":true},
    name: "tippers-edge"
  }, {
    path: "/tracks/",
    component: _427eaad2,
    pathToRegexpOptions: {"strict":true},
    name: "tracks"
  }, {
    path: "/trainers/",
    component: _5cefed30,
    pathToRegexpOptions: {"strict":true},
    name: "trainers"
  }, {
    path: "/watch/",
    component: _0ce5be1b,
    pathToRegexpOptions: {"strict":true},
    name: "watch"
  }, {
    path: "/betting/bonus-bets/",
    component: _85c7a46c,
    pathToRegexpOptions: {"strict":true},
    name: "betting-bonus-bets"
  }, {
    path: "/form-guide/harness/",
    component: _73701fde,
    pathToRegexpOptions: {"strict":true},
    name: "form-guide-harness"
  }, {
    path: "/form-guide/horse-racing/",
    component: _2f515466,
    pathToRegexpOptions: {"strict":true},
    name: "form-guide-horse-racing"
  }, {
    path: "/free-horse-racing-tips/best-bets/",
    component: _051a1a09,
    pathToRegexpOptions: {"strict":true},
    name: "free-horse-racing-tips-best-bets"
  }, {
    path: "/free-horse-racing-tips/best-value/",
    component: _19c9620c,
    pathToRegexpOptions: {"strict":true},
    name: "free-horse-racing-tips-best-value"
  }, {
    path: "/tracks/by-letter/",
    component: _60890cdf,
    pathToRegexpOptions: {"strict":true},
    name: "tracks-by-letter"
  }, {
    path: "/tracks/by-state/",
    component: _46dbd58e,
    pathToRegexpOptions: {"strict":true},
    name: "tracks-by-state"
  }, {
    path: "/",
    component: _1479741b,
    pathToRegexpOptions: {"strict":true},
    name: "index"
  }, {
    path: "/blackbook/unsubscribe/:token?/",
    component: _7cf19e46,
    pathToRegexpOptions: {"strict":true},
    name: "blackbook-unsubscribe-token"
  }, {
    path: "/tracks/by-letter/:letter?/",
    component: _45526adc,
    pathToRegexpOptions: {"strict":true},
    name: "tracks-by-letter-letter"
  }, {
    path: "/tracks/by-state/:state?/",
    component: _d4b91af4,
    pathToRegexpOptions: {"strict":true},
    name: "tracks-by-state-state"
  }, {
    path: "/form-guide/harness/:slug/race/:raceNumber?/",
    component: _f289e738,
    pathToRegexpOptions: {"strict":true},
    name: "form-guide-harness-slug-race-raceNumber"
  }, {
    path: "/bookie-reviews/:slug?/",
    component: _e9f61756,
    pathToRegexpOptions: {"strict":true},
    name: "bookie-reviews-slug"
  }, {
    path: "/form-guide/:raceType/",
    component: _b4563590,
    pathToRegexpOptions: {"strict":true},
    name: "form-guide-raceType"
  }, {
    path: "/free-horse-racing-tips/:slug?/",
    component: _548118c8,
    pathToRegexpOptions: {"strict":true},
    name: "free-horse-racing-tips-slug"
  }, {
    path: "/horses/:slug/",
    component: _67b02c3b,
    pathToRegexpOptions: {"strict":true},
    name: "horses-slug"
  }, {
    path: "/jockeys/:slug/",
    component: _286500b9,
    pathToRegexpOptions: {"strict":true},
    name: "jockeys-slug"
  }, {
    path: "/joinlink/:bookmakerId/",
    component: _fa717964,
    pathToRegexpOptions: {"strict":true},
    name: "joinlink-bookmakerId"
  }, {
    path: "/link/:bookmakerId/",
    component: _1039a990,
    pathToRegexpOptions: {"strict":true},
    name: "link-bookmakerId"
  }, {
    path: "/race-updates/:updateTypeSlug/",
    component: _4c6d5e54,
    pathToRegexpOptions: {"strict":true},
    name: "race-updates-updateTypeSlug"
  }, {
    path: "/races/:parentSlug/",
    component: _76518ee0,
    pathToRegexpOptions: {"strict":true},
    name: "races-parentSlug",
    children: [{
      path: ":childSlug?/",
      component: _07bfaff7,
      pathToRegexpOptions: {"strict":true},
      name: "races-parentSlug-childSlug"
    }]
  }, {
    path: "/racing-results/:raceType/",
    component: _7b287b7c,
    pathToRegexpOptions: {"strict":true},
    name: "racing-results-raceType"
  }, {
    path: "/tracks/:slug/",
    component: _40c8098a,
    pathToRegexpOptions: {"strict":true},
    name: "tracks-slug"
  }, {
    path: "/trainers/:slug/",
    component: _27b756a3,
    pathToRegexpOptions: {"strict":true},
    name: "trainers-slug"
  }, {
    path: "/unsubscribe/:token?/",
    component: _910e3b02,
    pathToRegexpOptions: {"strict":true},
    name: "unsubscribe-token"
  }, {
    path: "/watch/:category/",
    component: _7ba6b7a9,
    pathToRegexpOptions: {"strict":true},
    name: "watch-category"
  }, {
    path: "/form-guide/:raceType?/:meetingSlug/",
    component: _3bd6c814,
    pathToRegexpOptions: {"strict":true},
    name: "form-guide-raceType-meetingSlug"
  }, {
    path: "/horses/:slug/:tab/",
    component: _16113340,
    pathToRegexpOptions: {"strict":true},
    name: "horses-slug-tab"
  }, {
    path: "/jockeys/:slug/:tab/",
    component: _40437d82,
    pathToRegexpOptions: {"strict":true},
    name: "jockeys-slug-tab"
  }, {
    path: "/racing-results/:raceType?/:meetingSlug/",
    component: _4f1eff60,
    pathToRegexpOptions: {"strict":true},
    name: "racing-results-raceType-meetingSlug"
  }, {
    path: "/trainers/:slug/:tab/",
    component: _c1276c50,
    pathToRegexpOptions: {"strict":true},
    name: "trainers-slug-tab"
  }, {
    path: "/watch/:category/:videoSlug/",
    component: _96b99ffa,
    pathToRegexpOptions: {"strict":true},
    name: "watch-category-videoSlug"
  }, {
    path: "/form-guide/:raceType?/:meetingSlug/:eventSlug/",
    component: _e8f3d9d8,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "box-stats/",
      component: _ea3c303c,
      pathToRegexpOptions: {"strict":true},
      name: "form-guide-raceType-meetingSlug-eventSlug-box-stats"
    }, {
      path: "full-form/",
      component: _3e448887,
      pathToRegexpOptions: {"strict":true},
      name: "form-guide-raceType-meetingSlug-eventSlug-full-form"
    }, {
      path: "odds-comparison/",
      component: _ce310452,
      pathToRegexpOptions: {"strict":true},
      name: "form-guide-raceType-meetingSlug-eventSlug-odds-comparison"
    }, {
      path: "overview/",
      component: _2f97d844,
      pathToRegexpOptions: {"strict":true},
      name: "form-guide-raceType-meetingSlug-eventSlug-overview"
    }, {
      path: "speedmap/",
      component: _6fd34f00,
      pathToRegexpOptions: {"strict":true},
      name: "form-guide-raceType-meetingSlug-eventSlug-speedmap"
    }, {
      path: "",
      component: _c9960ad2,
      pathToRegexpOptions: {"strict":true},
      name: "form-guide-raceType-meetingSlug-eventSlug"
    }]
  }, {
    path: "/racing-results/:raceType?/:meetingSlug?/:eventSlug/",
    component: _5d09f64a,
    pathToRegexpOptions: {"strict":true},
    name: "racing-results-raceType-meetingSlug-eventSlug"
  }, {
    path: "/:slug/",
    component: _12c2d2d3,
    pathToRegexpOptions: {"strict":true},
    name: "slug"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
