import dayjs from 'dayjs';
import Utc from 'dayjs/plugin/utc';
import Timezone from 'dayjs/plugin/timezone';
dayjs.extend(Utc);
dayjs.extend(Timezone);

/**
 * This method converts the news data obtained from Punters Web API
 * to the news article data required by News Item component in
 * Core Component Library.
 *
 * @param {Array} articlesData - The Data node from Punters Web API
 * @returns {Object} an object that represent the structure of the news
 * articles from content API
 */
export const convertPuntersNewArticleForSTS = articlesData => {
  const newsArticlesResult = {
    newsArticles: [],
    total: 0,
    totalPages: 0,
  };

  for (const punterArticle of articlesData) {
    newsArticlesResult.newsArticles.push(fromPunterNewsToNewsItem(punterArticle));
  }

  // calculate the total pages
  newsArticlesResult.total = newsArticlesResult.newsArticles.length;
  newsArticlesResult.totalPages = Math.ceil(newsArticlesResult.total / 5);

  return newsArticlesResult;
};

const fromPunterNewsToNewsItem = punterNews => {
  return {
    id: punterNews._newsID,
    title: punterNews._title,
    slug: punterNews?._pageUrl?.replace('/news/', '') || '',
    state: [],
    excerpt: punterNews._textSummary,
    teaser: punterNews._textSummaryTrimmed100,
    commentsDisabled: true,
    externalAuthor: '',
    externalId: '',
    datePublished: dayjs(punterNews._publishDate * 1000).tz('Australia/Melbourne'),
    dateModified: dayjs(punterNews._modDate * 1000).tz('Australia/Melbourne'),
    customJsonSchema: '',
    image: convertNewsArticleImage(punterNews),
    categories: convertNewsArticleCategories(punterNews),
    author: convertNewsArticleAuthor(punterNews),
  };
};

const convertNewsArticleImage = puntersNews => {
  if (!puntersNews._hasPhotos || !puntersNews._photos || puntersNews._photos.length === 0) {
    return {};
  }
  return {
    id: puntersNews._photos[0]._photoID,
    url: puntersNews._photos[0]._URL,
    alt: puntersNews._photos[0].__title,
    description: '',
    caption: '',
    width: '',
    height: '',
    thumbnailUrl: puntersNews._photos[0]._URL308x240,
    mediumUrl: puntersNews._photos[0]._URL636x424,
  };
};

const convertNewsArticleCategories = punterNews => {
  return [
    {
      id: 0,
      name: punterNews?._label?.toUpperCase() || '',
      slug: punterNews?._label?.toLowerCase().replace(/ /g, '-') || '',
      colour: punterNews?._labelStyle || '',
      display: punterNews?._labelStyle?.length > 0 || false,
    },
  ];
};

const convertNewsArticleAuthor = punterNews => {
  return {
    id: 1, // Needs this so that it does not use external author value.
    name: punterNews._publisher,
  };
};
