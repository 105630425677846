import axios from 'axios';

/**
 * This used to have a cache layer in here. It's been temporarily removed because we were having weird issues and we
 * wanted to eliminate this as the cause.
 * @param url
 * @param options
 * @param timeout 10 secs
 * @returns {Promise<void>}
 */
const dataFetch = async function (url, options = {}, timeout = 10000) {
  const res = await axios
    .get(url, options, { timeout })
    .then(({ data }) => data)
    .catch(e => {
      console.error(e.message);
    });
  return res;
};

const dataPost = async function (url, params = {}) {
  const res = await axios.post(url, params);
  return res.data;
};
export { dataFetch, dataPost };
