import API_END_POINTS from '../api';
import { dataFetch } from '../api/data-fetch';

export const state = () => ({
  nextEvent: {},
});

export const actions = {
  async fetchNextToJump({ commit }, { id }, fetcher = dataFetch) {
    try {
      // region 24 is HK and region 1 is Australia
      const params = id === 'sectionals' ? '&regionId[]=1&regionId[]=24' : id === 'punters-edge' ? '&regionId[]=1' : '';
      const endpoint = `${process.env.BASE_URL}${API_END_POINTS.NEXT_TO_JUMP.EVENTS}${params}`;
      const res = await fetcher(endpoint);
      commit('SET_NEXT_EVENT', res?.events?.length ? res.events[0] : {});
    } catch (error) {
      console.error('error', error);
    }
  },
};

export const mutations = {
  SET_NEXT_EVENT: (state, event) => {
    state.nextEvent = event;
  },
};

export const getters = {
  getNextEvent(state) {
    return state.nextEvent;
  },
};
