const state = () => ({
  expandGraphqlLimit: false,
});

const mutations = {
  SET_GRAPHQL_LIMIT: (state, isExpand) => {
    state.expandGraphqlLimit = isExpand;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
