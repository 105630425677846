const state = () => ({
  menuNavigation: [],
  footerNavigation: [],
});

const mutations = {
  SET_MENU_NAVIGATION: (state, menuNavigation) => {
    state.menuNavigation = menuNavigation;
  },
  SET_FOOTER_MENU_NAVIGATION: (state, footerNavigation) => {
    state.footerNavigation = footerNavigation;
  },
  SET_NAV_OPEN: (state, text) => {
    const menuNavigation = state.menuNavigation.map(item => {
      if (item.text === text) {
        item.nav_open = !item.nav_open;
      }
      return item;
    });
    state.menuNavigation = menuNavigation;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
