const state = () => ({
  isLoading: false,
});

const actions = {
  setLoading({ commit }, value) {
    commit('SET_IS_LOADING', value);
  },
};

const mutations = {
  SET_IS_LOADING(state, value) {
    state.isLoading = value;
  },
};

const getters = {
  isLoading: state => {
    return state.isLoading;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
