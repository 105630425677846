import Vue from 'vue';
import { getUserData } from 'babel-loader!@newsperform/core-component-lib/src/utils/auth';

/**
 * Insert a script into DOM asynchronously
 *
 * @param {string} src
 */
const loadScriptAsync = src =>
  // eslint-disable-next-line promise/param-names
  new Promise(onload =>
    document.head.appendChild(
      Object.assign(document.createElement('script'), {
        async: true,
        onload,
        src,
      })
    )
  );

export const useRampart = config =>
  new Vue({
    data() {
      return {
        authenticated: false,
        initialized: false,
        loading: true,
        token: '',
        user: null,
      };
    },
    async created() {
      if (typeof document === 'undefined') return;

      await loadScriptAsync(config.AUTH_RAMPART_URI);

      // eslint-disable-next-line no-undef
      this.client = new Rampart({
        site: 'punters',
        callbackUri: config.AUTH_CALLBACK_URI,
        logoutUri: config.AUTH_LOGOUT_URI,
        sourceCode: 'PUWEB_WRE123',
      });
      this.initialized = true;
      try {
        await this.client.getLoginStatus(); // throws if not authenticated
        this.authenticated = await this.client.isAuthenticated();
        this.token = await this.client.getToken();
        this.user = await getUserData(this.token, config.AUTH_USER_DATA_ENDPOINT);
      } catch (error) {
        this.authenticated = false;
      }

      this.loading = false;
    },
    methods: {
      login(redirectUrl) {
        this.client.login(redirectUrl ?? window.location.href, { sourceCode: 'PUWEB_WRE123' });
      },
      logout(redirectUrl) {
        this.client.ssoLogout(redirectUrl ?? window.location.href);
      },
      signup() {
        this.client.signup(window.location.href);
      },
    },
  });

export default function ({ $config }) {
  Vue.use({
    install(Vue, _) {
      Vue.prototype.$auth = useRampart($config);
    },
  });
}
