import { getOptionsData } from 'babel-loader!@newsperform/core-component-lib/src/utils/options';
export default async function ({ $config, $gtm, route }) {
  const isApp = route?.query?.isApp === 'true';
  if (!$config.TESTENV && !process.server && !isApp) {
    const data = await getOptionsData($config.NUXT_ENV_OPTIONS_API_URL);
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ T: data?.T || '', S: data?.S || '' });
    $gtm.init('GTM-T6G52J');
  }
}
