import { menuByBrand } from 'babel-loader!@newsperform/core-component-lib/src/graphql/Menu/menuQueries.js';
import { menuObjectReFormat } from 'babel-loader!@newsperform/core-component-lib/src/utils/menuNavigation.js';

const actions = {
  async nuxtServerInit({ commit }) {
    const { app, $config } = this;

    // fetch menu from cms graphql content api
    const fetchMenuNavigation = app.apolloProvider.clients.contentClient
      .query({
        query: menuByBrand,
        variables: {
          brand: $config.BRAND,
        },
      })
      .then(({ data }) => data?.menu || []);
    const fetchFooterNavigation = app.apolloProvider.clients.contentClient
      .query({
        query: menuByBrand,
        variables: {
          brand: $config.BRAND,
          menuSlug: 'footer-menu',
        },
      })
      .then(({ data }) => data?.menu || []);
    const promiseArrayList = [fetchMenuNavigation, fetchFooterNavigation];
    const [menuNavigation, footerNavigation] = await Promise.all(promiseArrayList);
    const menu = [];
    const footerMenu = [];

    if (menuNavigation && menuNavigation.length) {
      menuNavigation.forEach(item => {
        menu.push(menuObjectReFormat(item));
      });
    }
    if (footerNavigation && footerNavigation.length) {
      footerNavigation.forEach(item => {
        footerMenu.push(menuObjectReFormat(item));
      });
    }

    commit('Navigation/SET_FOOTER_MENU_NAVIGATION', footerMenu);
    commit('Navigation/SET_MENU_NAVIGATION', menu);
  },
};

export default {
  actions,
};
