<script>
import Page404 from 'babel-loader!@newsperform/core-component-lib/src/components/Page404/Page404';
import Page404Search from 'babel-loader!@newsperform/core-component-lib/src/components/Page404/Page404Search';
export default {
  name: 'Error',
  components: {
    Page404,
    Page404Search,
  },
  props: {
    error: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<template>
  <div class="error">
    <Page404 v-if="error.statusCode === 404" brand="punters" />
    <div v-if="error.statusCode === 200">
      <div class="error-200__header">
        <h1>Oops, It looks like something has gone wrong.</h1>
        <h2>Please try again in a moment.</h2>
      </div>
      <Page404Search />
    </div>
  </div>
</template>

<style lang="scss">
@import '~@newsperform/core-component-lib/src/components/Page404/styles/punters/';
.error-200 {
  &__header {
    padding: 60px 0;
    text-align: center;
    background-color: $pothos;
    font-size: 28px;
    line-height: 32px;
    h1,
    h2 {
      color: $white;
    }
  }
}
</style>
