<script>
export default {
  methods: {
    start() {
      this.$store.dispatch('Loading/setLoading', true);
    },
    finish() {
      this.$store.dispatch('Loading/setLoading', false);
    },
  },
};
</script>

<template><div></div></template>
