import API_END_POINTS from '../api/index';
import { dataFetch } from '../api/data-fetch';
import { convertPuntersNewArticleForSTS } from '../utils/slugUtils';

const state = () => ({
  newsArticles: {
    newsArticles: [],
    total: 0,
    totalPages: 0,
  },
});

const actions = {
  async fetchNewsArticles(state, { slugType, slugID, limit }, fetcher = dataFetch) {
    const slugTypeMapping = {
      horse: 'competitor',
      jockey: 'jockey',
      trainer: 'trainer',
      venue: 'venue',
    };
    const endpoint = API_END_POINTS.NEWS_BY_SLUG_ID(slugTypeMapping[slugType], slugID, limit);
    const res = await fetcher(endpoint);
    state.commit('SET_NEWS_ARTICLES', res);
  },
};

const mutations = {
  SET_NEWS_ARTICLES: (state, articleData) => {
    if (articleData && articleData.isOkay && articleData.data) {
      state.newsArticles = convertPuntersNewArticleForSTS(articleData.data);
    }
  },
};

const getters = {
  getPunterNewsArticles: state => {
    return state.newsArticles;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
