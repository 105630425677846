/* eslint-disable no-undef */
export default (graphqlError, { route }) => {
  const { networkError, graphQLErrors } = graphqlError;
  let errorMessage;
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) => {
      const error = `[GraphQL Error]: Message: ${message}, Location:Ln ${locations[0].line} col ${locations[0].column}, GQL Path: ${path}, Full path: ${route.fullPath}`;
      if (!errorMessage) errorMessage = error;
      console.error(error);
    });
  }
  if (networkError) {
    const error = `[Network Error]: ${networkError}, Full path: ${route.fullPath}`;
    console.error(error);
    errorMessage = error;
  }

  if ((graphQLErrors && graphQLErrors.length > 0) || networkError)
    console.error(JSON.stringify(graphqlError, null, '  '));

  if (typeof window !== 'undefined' && window.rg4js) {
    window.rg4js('send', {
      error: errorMessage,
      customData: {
        graphqlError,
        url: route.fullPath,
      },
      tags: ['graphql'],
    });
  }
};
