import API_END_POINTS from '../api';
import { dataFetch } from '../api/data-fetch';

const state = () => ({
  user: {},
});

const mutations = {
  SET_USER_DETAILS(state, userDetails) {
    state.user = userDetails;
  },
};

const actions = {
  async getUserFromSessionCookie({ commit }) {
    const userDetails = await getUserDetailsFromSession();
    commit('SET_USER_DETAILS', userDetails);
  },
};

const getters = {
  getUserDetails: state => {
    return state.user;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};

async function getUserDetailsFromSession() {
  try {
    const endpoint = `${API_END_POINTS.SESSION.GET_USER_DETAILS}`;
    const response = await dataFetch(endpoint);
    return response || {};
  } catch (error) {
    console.error(error);
  }
}
