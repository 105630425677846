import Vue from 'vue';
import {
  filterCountdown,
  sportsCalendar,
  sportsCalendarPunters,
  filterCountdownPunters,
  puntersDisplayTime,
} from 'babel-loader!@newsperform/core-component-lib/src/utils/vue-moment';
Vue.filter('countdown', filterCountdown);
Vue.filter('sportsCalendar', sportsCalendar);
Vue.filter('sportsCalendarPunters', sportsCalendarPunters);
Vue.filter('countdownPunters', filterCountdownPunters);
Vue.filter('puntersDisplayTime', puntersDisplayTime);
