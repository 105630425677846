import API_END_POINTS from '../api';
import { dataFetch } from '../api/data-fetch';

export const state = () => ({
  news: [],
});

export const actions = {
  async fetchNews({ commit }) {
    const endpoint = API_END_POINTS.NEWS;
    try {
      const res = await dataFetch(endpoint);
      commit('SET_NEWS', res);
    } catch (error) {
      console.error('error', error);
    }
  },
};

export const mutations = {
  SET_NEWS: (state, news) => {
    state.news = news;
  },
};

export const getters = {
  getNews(state) {
    return state.news;
  },
};
