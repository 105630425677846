export default function ({ req, store, $device }) {
  let isMobile;

  if (!req || !req.headers) {
    return;
  }

  // set device OS
  store.commit('Global/SET_IS_IOS', $device.isIos);
  store.commit('Global/SET_IS_ANDROID', $device.isAndroid);

  if (req.headers.hasOwnProperty('cloudfront-is-mobile-viewer')) {
    // behind cloudfront, user-agent may not be available
    isMobile =
      req.headers['cloudfront-is-mobile-viewer'] === 'true' && req.headers['cloudfront-is-tablet-viewer'] !== 'true';
    store.commit('Global/SET_IS_MOBILE', isMobile);
  } else if (req.headers.hasOwnProperty('user-agent')) {
    store.commit('Global/SET_IS_MOBILE', $device.isMobile);
  }
}
