import fetch from 'node-fetch';
import { createPersistedQueryLink } from 'apollo-link-persisted-queries';
import { createHttpLink } from 'apollo-link-http';
import { ApolloLink } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';
import errorLink from './errorLink';

export default function createContentApolloClient(context) {
  const cache = new InMemoryCache();

  const persistedQueryLink = createPersistedQueryLink({ useGETForHashedQueries: true });
  const httpLink = createHttpLink({
    fetch,
    uri: context ? context.$config.NUXT_ENV_CONTENT_API_URL : process.env.NUXT_ENV_CONTENT_API_URL,
  });

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        authorization: `Bearer none`,
        ...headers,
      },
    };
  });

  return {
    link: ApolloLink.from([authLink, errorLink, persistedQueryLink, httpLink]),
    cache,
    defaultHttpLink: false,
  };
}
