const middleware = {}

middleware['Bookmakers'] = require('../middleware/Bookmakers.js')
middleware['Bookmakers'] = middleware['Bookmakers'].default || middleware['Bookmakers']

middleware['Breadcrumbs'] = require('../middleware/Breadcrumbs.js')
middleware['Breadcrumbs'] = middleware['Breadcrumbs'].default || middleware['Breadcrumbs']

middleware['HeaderConfig'] = require('../middleware/HeaderConfig.js')
middleware['HeaderConfig'] = middleware['HeaderConfig'].default || middleware['HeaderConfig']

middleware['Mobile'] = require('../middleware/Mobile.js')
middleware['Mobile'] = middleware['Mobile'].default || middleware['Mobile']

middleware['PageView'] = require('../middleware/PageView.js')
middleware['PageView'] = middleware['PageView'].default || middleware['PageView']

middleware['Redirect'] = require('../middleware/Redirect.js')
middleware['Redirect'] = middleware['Redirect'].default || middleware['Redirect']

export default middleware
