// "from" paths should
import {
  groupRacesRedirect,
  constructRedirectList,
} from 'babel-loader!@newsperform/core-component-lib/src/utils/legacyGroupRacesRedirect';
import { BRAND_PUNTERS } from 'babel-loader!@newsperform/core-component-lib/src/utils/nameLibrary';

const redirects = [
  {
    from: '/help/login/',
    to: '/faq/',
    httpStatus: 301,
  },
  {
    from: '/help/rewards/',
    to: '/faq/',
    httpStatus: 301,
  },
  {
    from: '/subscriptionterms/',
    to: 'https://myaccount.news.com.au/punters/help/subscriptionTerms?pkgDef=PU_SDO_21M01#subscribe',
    httpStatus: 301,
  },

  {
    from: '/appsubscriptionterms/',
    to: 'https://myaccount.news.com.au/punters/help/subscriptionTerms?pkgDef=PU_AAP_F99921PEP#subscribe',
    httpStatus: 301,
  },
  {
    from: '/download-our-app/',
    to: ({ store }) => {
      if (store.state.Global.isIos) {
        return 'https://itunes.apple.com/us/app/punters-horse-racing-form/id916114449?ls=1&ct=website_footer&mt=8';
      } else if (store.state.Global.isAndroid) {
        return 'https://play.google.com/store/apps/details?id=au.com.punters.punterscomau';
      } else {
        return undefined;
      }
    },
    httpStatus: 301,
  },
  {
    from: '/race-updates/',
    to: '/race-updates/scratchings/',
    httpStatus: 301,
  },
  ...constructRedirectList(groupRacesRedirect, BRAND_PUNTERS),
];

export default context => {
  const {
    redirect,
    error,
    route: { path, fullPath },
  } = context;

  // Add trailing slash if not present
  if (path.slice(-1) !== '/') {
    redirect(301, path + '/');
    return;
  }

  if (path.includes('special-races')) {
    const pathArray = path.split('/');
    if (pathArray.length >= 4) {
      redirect(301, `/notable-races/${pathArray[2]}`);
      return;
    } else if (pathArray.length === 3) {
      redirect(301, `/notable-races/`);
      return;
    }
  }

  if (fullPath.includes('fullsite')) {
    const params = new URLSearchParams(fullPath.split('?')[1]);
    params.delete('fullsite');
    redirect(301, fullPath.split('?')[0] + '?' + params.toString());
  }

  const redirectToPerform = redirects.find(_ => _.from === path);
  if (redirectToPerform) {
    const dest = typeof redirectToPerform.to === 'function' ? redirectToPerform.to(context) : redirectToPerform.to;

    if (!dest) {
      error({ statusCode: 404, message: 'Page not found' });
    } else {
      redirect(redirectToPerform.httpStatus, dest);
    }
  }
};
