// these strings match values in CDK stack
export const stages = { PROD: 'prod', STAGING: 'staging', LOCAL: 'local' };

export default (context, inject) => {
  const stage = context.$config.STAGE ? context.$config.STAGE : stages.PROD;

  inject('stage', stage);
  inject('isLocal', stage === stages.LOCAL);
  inject('isProd', stage === stages.PROD);
  inject('isStaging', stage === stages.STAGING);
  inject('isPunters', true);
  inject('enableExternalTracking', !context.$config.NO_EXTERNAL_TRACKING);
  if (context.$config.NO_EXTERNAL_TRACKING) {
    console.warn('3rd-party ads & tracking disabled');
  }
};
