import { createPersistedQueryLink } from 'apollo-link-persisted-queries';
import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { ApolloLink } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import introspectionQueryResultData from '../../graphql/fragmentTypes.json';
import { customFetch } from './customFetch';
import errorLink from './errorLink';

export default function createHorseRacingApolloClient(context) {
  const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData,
  });

  const cache = new InMemoryCache({ fragmentMatcher });
  const persistedQueryLink = createPersistedQueryLink({ useGETForHashedQueries: true });
  const httpLink = createHttpLink({
    fetch: customFetch,
    uri: context ? context.$config.NUXT_ENV_HORSERACING_API_URL : process.env.NUXT_ENV_HORSERACING_API_URL,
  });

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        authorization: `Bearer none`,
        ...headers,
      },
    };
  });

  return {
    link: ApolloLink.from([authLink, errorLink, persistedQueryLink, httpLink]),
    cache,
    defaultHttpLink: false,
  };
}
