const API_KEY = '?APIKey=65d5a3e79fcd603b3845f0dc7c2437f0';
const PUNT_API = 'https://puntapi.com';
const PUNTERS_API_WEB_PUBLIC = 'https://www.punters.com.au/api/web/public';
const PUNTERS_API_WEB = 'https://www.punters.com.au/api/web';

const API_END_POINTS = {
  SESSION: {
    GET_USER_DETAILS: `/api/web/public/UserProfile/getProfileDetails/${API_KEY}`,
    GET_USER_PRIVATE_MESSAGES: `/api/web/public/PrivateMessage/getMessages/${API_KEY}`,
    GET_USER_PRIVATE_MESSAGE_THREAD: `/api/web/public/PrivateMessage/getThread/${API_KEY}`,
    GET_USER_PRIVATE_CHANNEL_AUTH: `/api/web/public/PrivateMessage/authPrivateChannel/${API_KEY}`,
    SEND_PRIVATE_MESSAGE: `/api/web/public/PrivateMessage/sendMessage/${API_KEY}`,
  },
  HEADER_SEARCH: `/api/web/public/Search/getResults/${API_KEY}`,
  MEETING: {
    GET_GREYHOUND_MEETING: `/api/web/public/Meetings/getGreyhoundMeetingEventDetailsCacheable/${API_KEY}`,
  },
  HARNESS: {
    MEETING: `${PUNT_API}/racing/harness/meetings/`,
    EVENTS: `${PUNT_API}/racing/harness/events`,
    BY_MEETINGS_DATE: `${PUNT_API}/racing/harness/meetings`,
    STATS: `${PUNT_API}/racing/harness/stats/selections`,
  },
  ODDS: {
    EVENTS: `${PUNT_API}/odds/au/event/`,
  },
  VIDEO: {
    GET_ALL_PLAYLISTS: `${PUNT_API}/video/playlists?withVideos=true`,
  },
  NEWS: `${PUNTERS_API_WEB_PUBLIC}/News/getFeatured/${API_KEY}`,
  NEXT_TO_JUMP: {
    EVENTS: `/api/web/public/FormGuide/getNextToJumpMeetingsCacheable/${API_KEY}`,
  },
  NEWS_BY_SLUG_ID: (slugType, slugID, limit) => {
    return `${PUNTERS_API_WEB}/NewsItems/loadBySingleTag/?limit=${limit}&needsPhotos=false&tagType=${slugType}&tagValueInt=${slugID}&APIKey=65d5a3e79fcd603b3845f0dc7c2437f0`;
  },
  CONTACT_FORM_SUBMIT: `/api/web/public/FormSubmitions/submitContactForms/${API_KEY}`, // need to update this without the domain name. but for testing purposes, i will need to include local.vagrant
};

export default API_END_POINTS;
