/**
 *
 * @param {*} ctx  - context object
 */
const setEvidently = ctx => {
  const { store, req, query } = ctx;
  const headers = req.headers;
  const EVIDENTLY_FEATURES = {
    FORM_GUIDE_REBUILD: {
      name: 'formguideRebuild',
      variation: 'NUXT',
      variations: {
        LEGACY: 'LEGACY',
        NUXT: 'NUXT',
      },
    },
  };
  if (headers && (headers['x-amzn-evidently-features'] || query.evidentlyTestFeatures)) {
    try {
      const evidentlyFeatures = JSON.parse(query.evidentlyTestFeatures || headers['x-amzn-evidently-features']);
      Object.keys(evidentlyFeatures).forEach(key => {
        if (evidentlyFeatures[key]) {
          const findFeatureKey = Object.keys(EVIDENTLY_FEATURES).find(value => EVIDENTLY_FEATURES[value].name === key);
          if (findFeatureKey) {
            EVIDENTLY_FEATURES[findFeatureKey] = {
              name: EVIDENTLY_FEATURES[findFeatureKey].name,
              variations: EVIDENTLY_FEATURES[findFeatureKey].variations,
              ...evidentlyFeatures[key],
            };
          }
        }
      });
    } catch (e) {}
  }
  store.commit('Global/SET_EVIDENTLY_FEATURES', EVIDENTLY_FEATURES);
};

/**
 *
 * @param {*} ctx
 * header x-amzn-is-subscriber is used to set the subscriber status
 * @returns null
 */
const setSubscriber = ctx => {
  const { store, req, query, $isLocal } = ctx;
  if ($isLocal) {
    store.commit('Subscriptions/SET_IS_SUBSCRIBER', query?.isSubscriber);
    return;
  }

  const headers = req.headers;
  if (headers && headers['x-amzn-is-subscriber']) {
    store.commit('Subscriptions/SET_IS_SUBSCRIBER', headers['x-amzn-is-subscriber']);
  }
};

/**
 *
 * @param {*} ctx
 * header x-amzn-is-logged-in is used to set the login status
 * @returns
 */
const setLoggedIn = ctx => {
  const { store, req, query, $isLocal } = ctx;

  if ($isLocal) {
    store.commit('Subscriptions/SET_IS_LOGIN', query?.isloggedIn);
    return;
  }

  const headers = req.headers;
  if (headers && headers['x-amzn-is-logged-in']) {
    store.commit('Subscriptions/SET_IS_LOGIN', headers['x-amzn-is-logged-in']);
  }
};

export default function (ctx) {
  if (process.server) {
    setEvidently(ctx);
    setSubscriber(ctx);
    setLoggedIn(ctx);
  }
}
