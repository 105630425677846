import { onError } from 'apollo-link-error';

const errorLink = onError(data => {
  const { graphQLErrors, networkError } = data;

  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) => {
      console.error(
        `[GraphQL error]: Message: ${message}, Location: Ln ${locations[0].line} col ${locations[0].column}, Path: ${path}`
      );
    });
  }
  if (networkError) {
    console.error(`[Network error]: ${networkError}`);
  }

  if ((graphQLErrors && graphQLErrors.length > 0) || networkError) console.error(JSON.stringify(data, null, '  '));
});

export default errorLink;
