import { getOptionsData } from 'babel-loader!@newsperform/core-component-lib/src/utils/options';

export default async function ({ store, app, $config, route, $gtm }) {
  if (store.state.Global.isInitialRoute) {
    store.commit('Global/SET_IS_INITIAL_ROUTE', false);
    const data = await getOptionsData($config.NUXT_ENV_OPTIONS_API_URL);
    store.commit('Global/SET_BOT_SCORE', data);
  } else {
    $gtm.push({
      event: 'nuxtRoute',
      pageTitle: app.head.title,
      pageType: 'PageView',
      pageUrl: `${$config.BASE_URL}${route.fullPath}`,
      routeName: route.name,
    });
  }
}
