import API_END_POINTS from '../api';
import { dataFetch, dataPost } from '../api/data-fetch';

const state = () => ({
  messageModalActive: false,
  privateMessages: {},
  messageThreadActive: false,
  messageThread: {},
});

const mutations = {
  SET_USER_MESSAGE_MODAL_ACTIVE(state, isActive) {
    state.messageModalActive = isActive;
  },
  SET_USER_PRIVATE_MESSAGES(state, userMessages) {
    state.privateMessages = userMessages;
  },
  SET_USER_PRIVATE_MESSAGE_THREAD(state, messageThread) {
    state.messageThread = messageThread;
  },
  SET_USER_THREAD_ACTIVE(state, isActive) {
    state.messageThreadActive = isActive;
  },
};

const actions = {
  async getUserPrivateMessages({ commit }) {
    const userMessages = await getUserPrivateMessagesFromSession();
    commit('SET_USER_PRIVATE_MESSAGES', userMessages);
  },
  async togglePrivateMessageModal({ commit }, { otherUserId }) {
    // get thread and set modal active
    const messageThread = await getPrivateMessageThreadFromSession(otherUserId);
    commit('SET_USER_PRIVATE_MESSAGE_THREAD', messageThread);
    commit('SET_USER_THREAD_ACTIVE', true);
    // refresh user messages
    const userMessages = await getUserPrivateMessagesFromSession();
    commit('SET_USER_PRIVATE_MESSAGES', userMessages);
  },
  async refreshThread({ commit }, { otherUserId }) {
    const messageThread = await getPrivateMessageThreadFromSession(otherUserId);
    commit('SET_USER_PRIVATE_MESSAGE_THREAD', messageThread);
  },
  async sendPrivateMessage({ commit }, { receiverId, message }) {
    const endpoint = `${API_END_POINTS.SESSION.SEND_PRIVATE_MESSAGE}`;
    const params = {
      receiverId,
      message,
    };
    const response = await dataPost(endpoint, params);
    if (response) {
      const otherUserId = receiverId;
      const messageThread = await getPrivateMessageThreadFromSession(otherUserId);
      commit('SET_USER_PRIVATE_MESSAGE_THREAD', messageThread);
    }
  },
};

const getters = {
  isMessageModalActive: state => {
    return state.messageModalActive;
  },
  getPrivateMessages: state => {
    return state.privateMessages;
  },
  isMessageThreadActive: state => {
    return state.messageThreadActive;
  },
  getPrivateMessageThread: state => {
    return state.messageThread;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};

async function getUserPrivateMessagesFromSession() {
  try {
    const endpoint = `${API_END_POINTS.SESSION.GET_USER_PRIVATE_MESSAGES}`;
    const response = await dataFetch(endpoint);

    return response || {};
  } catch (error) {
    console.error(error);
  }
}

async function getPrivateMessageThreadFromSession(senderUserId) {
  try {
    const endpoint = `${API_END_POINTS.SESSION.GET_USER_PRIVATE_MESSAGE_THREAD}&userId=${senderUserId}`;
    const options = {
      withCredentials: false,
    };
    const response = await dataFetch(endpoint, options);

    return response || {};
  } catch (error) {
    console.error(error);
  }
}
