/**
 * Compile page route path into an array
 * which can meet the structure requirement for template.
 *
 * For example:
 * Make path route `news/slug_text` into like below
 *  [{
 *    name: 'news',
 *    path: '/news/'
 *  }, {
 *   name: 'slug_text',
 *   path: '/news/slug_text/'
 * }]
 *
 * @param {Object} context
 * @param {Object} context.store
 * @param {Object} context.route
 * @param {String} context.route.path
 */
import {
  RACE_TYPE_GREYHOUNDS,
  RACE_TYPE_HORSE_RACING,
  RACE_TEXT_BY_TYPE,
  RACE_TYPE_HARNESS,
} from 'babel-loader!@newsperform/core-component-lib/src/utils/nameLibrary.js';
export default ({ store, route: { path } }) => {
  if (path === '/') return;

  const breadcrumbs = [];
  let paths = path.split('/').filter(item => !!item);

  // Pages with breadcrumbs that need venue IDs trimmed off
  const pagesWithIds = [
    {
      name: 'free-horse-racing-tips',
      slug: 'free-horse-racing-tips',
    },
    {
      name: 'Tracks',
      slug: 'tracks',
    },
  ];

  const pagesWithIdSlugs = pagesWithIds.map(page => page.slug);
  const shortenPath = paths.find(pathComponent => pagesWithIdSlugs.includes(pathComponent));

  if (shortenPath) {
    const index = pagesWithIdSlugs.indexOf(shortenPath);
    const info = pagesWithIds[index];
    return removeIdFromBreadcrumb(paths, store, info.name, info.slug);
  } else if (paths.includes('race-updates')) {
    scratchingsUpdatesBreadcrumb(paths, store);
  } else if (paths.includes('racing-results')) {
    racingResultsBreadcrumb(paths, store);
  } else {
    while (paths.length > 0) {
      breadcrumbs.push({ name: paths[paths.length - 1], path: `/${paths.join('/')}` });
      paths = paths.slice(0, -1);
    }
    store.commit('Breadcrumbs/SET_BREADCRUMBS', { breadcrumbs: breadcrumbs.reverse() });
  }
};

function scratchingsUpdatesBreadcrumb(paths, store) {
  const breadcrumbs = [];

  breadcrumbs.push({ name: 'Race Updates', path: '/race-updates/scratchings' });

  paths.forEach((path, index) => {
    if (path !== 'race-updates') {
      breadcrumbs.push({ name: path, path });
    }
  });
  store.commit('Breadcrumbs/SET_BREADCRUMBS', { breadcrumbs });
}

function racingResultsBreadcrumb(paths, store) {
  const breadcrumbs = [];
  const raceType = paths[1];
  const raceTypeResultName = `${RACE_TEXT_BY_TYPE[raceType]} Results`;
  let accumulatedPath = '';

  paths.forEach((path, index) => {
    if (path === 'racing-results') {
      accumulatedPath += `/${path}`;
      return;
    }
    // race type results
    if (index === 1) {
      accumulatedPath += `/${path}`;
      breadcrumbs.push({ name: raceTypeResultName, path: accumulatedPath });
      return;
    }
    // Meeting Name
    if (index === 2 && paths.length > 3) {
      const meetingSlugItems = path.split('-');
      let venueName = '';
      let venuePath = '';
      if (raceType === RACE_TYPE_HORSE_RACING) {
        venuePath = meetingSlugItems.slice(0, -1).join('-');
        venueName = meetingSlugItems.slice(0, -1).join(' ');
      }

      if ([RACE_TYPE_GREYHOUNDS, RACE_TYPE_HARNESS].includes(raceType)) {
        venuePath = meetingSlugItems.slice(0, -3).join('-');
        venueName = meetingSlugItems.slice(0, -3).join(' ');
      }

      accumulatedPath += `/${venuePath}`;
      if (venueName) {
        venueName += ' Results';
      }
      breadcrumbs.push({ name: venueName, path: accumulatedPath });
      return;
    }

    accumulatedPath += `/${path}`;
    breadcrumbs.push({ name: path, path: accumulatedPath });
  });
  store.commit('Breadcrumbs/SET_BREADCRUMBS', { breadcrumbs });
}

const removeIdFromBreadcrumb = (paths, store, name, slug) => {
  const breadcrumbs = [];
  breadcrumbs.push({ name, path: `/${slug}` });
  paths.forEach(path => {
    if (path !== slug) {
      const trackName = path.split('_')[0];
      breadcrumbs.push({ name: trackName, path });
    }
  });
  store.commit('Breadcrumbs/SET_BREADCRUMBS', { breadcrumbs });
};
