const state = () => ({
  userDetails: '',
});

const mutations = {
  SET_USER_DETAILS: (state, userDetails) => {
    state.userDetails = userDetails;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
