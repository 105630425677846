import { createPersistedQueryLink } from 'apollo-link-persisted-queries';
import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { customFetch } from './customFetch';

export default function createGreyhoundsApolloClient(context) {
  const cache = new InMemoryCache();

  const link = createPersistedQueryLink({ useGETForHashedQueries: true }).concat(
    createHttpLink({
      fetch: customFetch,
      uri: context ? context.$config.NUXT_ENV_GREYHOUND_API_URL : process.env.NUXT_ENV_GREYHOUND_API_URL,
    })
  );

  return {
    link,
    cache,
    defaultHttpLink: false,
  };
}
